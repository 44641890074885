<!--
Banners
-->
<template>
  <!-- fix boxes -->
  <div class="infobar">
    <div class="container">
      <div class="row">
        <div v-if="this.data[0]" class="col-lg-4 bg-yellow mb-2 mb-lg-0">
          <div class="row">
            <div class="py-2">
              <div class="row">
                <div
                  :class="this.data[0].image ? 'col-lg-7' : 'col-lg-12'"
                  class="py-3 my-mb-1"
                >
                  <h5 class="text-blue fw-medium">{{ data[0].title }}</h5>
                  <div class="text-blue fs-6 py-2 banner-text">
                    {{ this.data[0].text }}
                  </div>
                </div>
                <div v-if="this.data[0].image" class="col-2 mob-hide">
                  <img
                    v-bind:src="this.data[0].image"
                    v-bind:alt="this.data[0].title"
                    class="mobile"
                    loading="lazy"
                  />
                </div>
                <div class="col-12 order-2">
                  <router-link
                    :to="this.data[0].link"
                    v-if="this.data[0].link"
                    class="px-2 text-blue text-decoration-none"
                    >{{$t('modules.interested')}}
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.data[1]" class="col-lg-8 bg-blue">
          <div class="row">
            <div class="col-lg-6 box-border">
              <div class="row">
                <div
                  :class="this.data[1].image ? 'col-lg-7' : 'col-lg-12'"
                  class="py-3 my-mb-1"
                >
                  <h5 class="text-white fw-medium">{{ data[1].title }}</h5>
                  <div class="text-lightblue fs-6 py-2 banner-text">
                    {{ this.data[1].text }}
                  </div>
                </div>
                <div v-if="this.data[1].image" class="col-2 mob-hide">
                  <img
                    v-bind:src="this.data[1].image"
                    v-bind:alt="this.data[1].title"
                    class="mobile"
                    loading="lazy"
                  />
                </div>
                <div class="col-12 order-2">
                  <router-link
                    :to="this.data[1].link"
                    v-if="this.data[1].link"
                    class="px-2 text-yellow text-decoration-none"
                    >{{$t('modules.interested')}}
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                  </router-link>
                </div>
              </div>
            </div>

            <div v-if="this.data[2]" class="col-lg-6">
              <div class="row">
                <div
                  :class="this.data[2].image ? 'col-lg-7' : 'col-lg-12'"
                  class="py-3"
                >
                  <h5 class="text-white fw-medium">{{ data[2].title }}</h5>
                  <div class="text-lightblue fs-6 py-2 banner-text">
                    {{ this.data[2].text }}
                  </div>
                </div>
                <div v-if="this.data[2].image" class="col-2 mob-hide">
                  <img
                    v-bind:src="this.data[2].image"
                    v-bind:alt="this.data[2].title"
                    class="mobile"
                    loading="lazy"
                  />
                </div>
                <div class="col-12 order-2">
                  <router-link
                    :to="this.data[2].link"
                    v-if="this.data[2].link"
                    class="px-2 text-yellow text-decoration-none"
                    >{{$t('modules.interested')}}
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "module10",
  props: {
    data: { data: [Array] },
  },
};
</script>

<style scoped></style>
